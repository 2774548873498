<script>
import axios from 'axios';

import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Donation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      isInvalidAmount: false,
      provider: 'monobank',
      anonymousDonation: false,
      amount: 0,
      message: '',
      
      //Episode data
      episode: {
        type: Object,
        default: function () {
          return {
            id: '',
            title: '',
            short: '',
            urlAvatar: '',
          }
        }
      }
    };
  },
  components: {
    Layout,
  },
  mounted() {
    this.loadingEpisode();
  },
  methods: {
    loadingEpisode() {
      axios.get('/api/episode/simple/' + this.$route.params.id, { withCredentials: this.isAuthProfile() })
        .then((response) => {
          this.episode = response.data.episode;
        })
        .catch(function () {
          console.log('Error');
        });
    },
    validateForm: function () {
      this.isInvalidAmount = this.validateAmountInput() || this.amount <= 0;

      if (!(this.isInvalidAmount || this.isInvalidMessage))
        return true;

      return false;
    },
    validateAmountInput() {
      const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
      return !regex.test(this.amount);
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getProviderUrl() {
      var session = this.$route.params.session;
      switch(this.provider) {
        case 'monobank':
          return '/api/payments/monobank-donation/' + session;
        default: //FONDY default
          return '/api/payments/fondy-donation/' + session;
      }
    },
    sendDonationForm: function () {
      console.log(this.isAuthProfile());
      var r = this.validateForm();
      if (r) {
        var data = {
          provider: this.provider,
          amount: this.amount,
          message: this.message,
          isAnonymous: this.anonymousDonation,
        };
        console.log('Donation data:', data);

        const vm = this;
        const donationUrl = this.getProviderUrl();
        axios.post(donationUrl, data, { withCredentials: !this.isAnonymous() }).then(function (e) {
          vm.modalShow = false;
          vm.amount = 0;
          vm.message = '';
          window.location.href = e.data.url;
        });
      }
    },
    isAuthProfile: function() {
      return localStorage.getItem('user.id') !== null;
    },
    isAnonymous: function() {
      if(this.isAuthProfile()) {
        return this.anonymousDonation;
      }
      else
        return true;
    }
  },
  computed: {
  }
};
</script>

<template>
  <Layout>

    <div class="container mt-4">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="text-center mb-4">
            <h3 class="mb-3 fw-semibold">Make donation</h3>
            <p class="text-muted">Show some love: help keep our content creators going with a voluntary donation today!
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 offset-lg-3">

          <div class="card card-body" v-if="episode.title">
            <div class="content">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="avatar-sm img-thumbnail rounded-circle flex-shrink-0">
                    <div class="avatar-title rounded-circle text-center" v-if="!episode.urlAvatar">{{ episode.title[0] }}</div>
                    <img v-bind:src="episode.urlAvatar" alt="" class="img-fluid d-block rounded-circle" v-if="episode.urlAvatar" />
                  </div>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="fs-14">{{ episode.title }} <small class="text-muted fs-13 fw-normal ms-1">{{ episode.short }}</small></h5>
                  <p class="text-muted mb-2">{{ episode.memo }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="providerMonobank" v-model="provider" value="monobank">
              <label class="form-check-label" for="providerMonobank">Monobank</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="providerFondy" v-model="provider" value="fondy" disabled="disabled">
              <label class="form-check-label" for="providerFondy">Fondy</label>
            </div>
          </div>

          <div class="form-floating">
            <input type="number" class="form-control" id="donateAmount" v-model="amount" @keypress="isNumber($event)"
              v-bind:class="{ 'is-invalid': isInvalidAmount }" maxlength="10">
            <label for="donateAmount">Amount (UAH)</label>
          </div>

          <div class="form-floating mt-3">
            <textarea class="form-control h-200" id="donateMessage" v-model="message" maxlength="256"></textarea>
            <label for="donateMessage">Your message (optional)</label>
          </div>

          <div class="form-check mt-3 mb-2" v-if="isAuthProfile()">
            <input class="form-check-input" type="checkbox" id="chkAnonymous" v-model="anonymousDonation">
            <label class="form-check-label" for="chkAnonymous">Anonymous donation</label>
          </div>

          <div class="text-center">
            <b-button type="button" variant="primary" class="mt-3" @click="sendDonationForm">Payment</b-button>
          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>